/*
 * Checkboxes for SLC Disclosure Lender List. It is part of the T&Cs.
 */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Control, actions } from 'react-redux-form';
import { reportFieldEngagement } from '../../bootstrap';
import { getStore } from '../../store';

const selectedWrapper = (props) => {
  let inputProps = Object.assign({}, props);
  delete inputProps.children;
  delete inputProps.submitted;
  // PHX-6608 :: INFO :: Zach James
  // id is set based on assumption of Jornaya being enabled, and having multiple Jornaya "disclosures"
  // root jornaya id starts at "1" and index starts at "0", so + 2 offset is used to fix numbering
  return (
    <label tabIndex="0" className={props.checked ? 'selected' : null}>
      <input
        {...inputProps}
        id={'leadid_tcpa_disclosure_' + (props.index + 2).toString()}
      />
      <label
        className="boxLabel"
        htmlFor={'leadid_tcpa_disclosure_' + (props.index + 2).toString()}
      >
        {''}
      </label>
      <label htmlFor={'leadid_tcpa_disclosure_' + (props.index + 2).toString()}>
        {props.children}
      </label>
    </label>
  );
};

selectedWrapper.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
};

const SlcDisclosureCheckboxes = ({ SLCLenderList }) => {
  let attributeid = 'consentedToPartnerIds';
  const model = `formData.${attributeid}[]`;

  // Checkboxes should be checked by default
  useEffect(() => {
    const currentSelections = getStore().getState().formData[attributeid];
    if (!currentSelections || currentSelections.length === 0) {
      const initialLendingEntityIds = SLCLenderList?.map(
        (lender) => lender.lendingEntityId
      );
      getStore().dispatch(
        actions.change(`formData.${attributeid}`, initialLendingEntityIds)
      );
    }
    getStore().dispatch(actions.validate(model, atLeastOneChecked));
  }, [SLCLenderList, attributeid]);

  // We need to use formData as the model for verification purposes but need the data to exist in formMeta, so
  // subscribe to the store and sync formMeta[attributeid] with formData[attributeid]
  useEffect(() => {
    const syncModelWithFormMeta = getStore().subscribe(() => {
      const formData = getStore().getState().formData[attributeid];
      const formMeta = getStore().getState().formMeta[attributeid];
      if (JSON.stringify(formData) !== JSON.stringify(formMeta)) {
        getStore().dispatch(
          actions.change(`formMeta.${attributeid}`, formData)
        );
      }
    });
    return () => syncModelWithFormMeta();
  }, [attributeid]);

  // Validator function to check if at least one checkbox is checked
  const atLeastOneChecked = () => {
    return getStore().getState().formData.consentedToPartnerIds?.length > 0;
  };

  return (
    <div className={`ltFormGroupContentSlcDisclosureTextboxes`}>
      {SLCLenderList?.map((value, index) => (
        <div key={value.lendingEntityId} className={`slcDisclosure-item`}>
          <Control.checkbox
            component={selectedWrapper}
            value={value.lendingEntityId}
            index={index}
            model={model}
            validators={{ atLeastOneChecked }}
            changeAction={(model, value) => (dispatch, getState) => {
              let currentSelections = getState().formData[`${attributeid}`];
              if (currentSelections) {
                let isRemoval = currentSelections.indexOf(value) !== -1;
                reportFieldEngagement(
                  `${attributeid}[lendingEntityId: ${value}]`,
                  isRemoval ? 'unchecked' : 'checked'
                );
              }
              dispatch(actions.xor(model, value));
              dispatch(actions.validate(model, atLeastOneChecked));
            }}
          >
            {value.partnerName}
          </Control.checkbox>
        </div>
      ))}
    </div>
  );
};

SlcDisclosureCheckboxes.propTypes = {
  checked: PropTypes.bool,
  fieldValue: PropTypes.object,
};

export default SlcDisclosureCheckboxes;
